<template>
  <q-table
    class="table-content"
    :rows-per-page-options="[50]"
    dense
    bordered
    card-class="shadow-5"
    card-style="border-radius: 20px"
    color="default-pink"
    :rows="list"
    :columns="columns"
    :filter="filter"
    row-key="name"
    virtual-scroll
  >
    <template v-slot:top-right>
      <div>
        <q-input
          borderless
          dense
          debounce="300"
          v-model="filter"
          placeholder="Pesquisar"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="name" :props="props">
          <div class="row items-center">
            <div class="content-img q-ma-sm">
              <q-img
                width="100%"
                height="100%"
                :src="url_aws_bucket"
                spinner-color="white"
              >
                <template v-slot:error>
                  <q-icon class="avatar" name="account_circle" size="40px" />
                </template>
              </q-img>
            </div>
          </div>
        </q-td>
        <q-td key="id" :props="props">
          {{ props.row.id }}
        </q-td>
        <q-td key="name" :props="props">
          {{ props.row.first_name }} {{ props.row.last_name }}
        </q-td>
        <q-td key="email" :props="props">
          {{ props.row.email }}
        </q-td>
        <q-td class="content-groups" key="company_name" :props="props">
          {{ props.row.company_name }}
        </q-td>
        <q-td class="content-groups" key="grupo" :props="props">
          <q-item-label
            caption
            lines="2"
            style="width: 80px; overflow: auto; color: black"
            >{{ props.row.name_group }}</q-item-label
          >
        </q-td>
        <q-td key="acao" :props="props">
          <div>
            <q-btn
              v-show="addEdit"
              dense
              round
              color="default-pink"
              flat
              :to="{ name: 'new-user', query: { id: props.row.id } }"
              icon="o_edit_note"
            />
            <q-btn
              v-show="addRemove"
              dense
              round
              color="default-pink"
              flat
              @click="disabled(props.row)"
              icon="o_delete_outline"
            />
          </div>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script>

import { ENV  } from "@/utils/env";
import UserServices from "@/services/UserServices";
import { useQuasar } from "quasar";

export default {
  name: "Table",
  props: {
    list: Array,
    columns: Array,
    addEdit: {
      type: Boolean,
      default: true,
    },
    addRemove: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    let Userservice = new UserServices();
    let $q = new useQuasar();

    async function disabled(user) {
      $q.notify({
        message: `Deseja remover o usuário ${user.id}`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              await disableUser(user.id);
            },
          },
        ],
      });
    }

    async function disableUser(id) {
      await Userservice.disableUserbyId(id)
        .then((response) => {
          $q.notify({
            message: "Exclusão feita com sucesso.",
            color: "green-9",
            position: "top",
          });
          setTimeout(function () {
            location.reload();
          }, 2000);
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    return {
      disabled,
      url_aws_bucket:ENV.URL_AWS_BUCKET,
      filter: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.content-img {
  overflow: hidden;
  border-radius: 50px;
  height: 50px;
  width: 50px;
}
</style>
