<template>
  <div class="tab-content">
    <div class="col-12">
      <div class="row q-pl-lg col-12">
        <div class="flex">
          <q-btn
              flat
              round
              color="default-pink"
              @click="scrollToLeft"
              icon="chevron_left"
          />
        </div>
        <div class="tab col-10">
          <slot></slot>
        </div>
        <div class="flex">
          <q-btn
              flat
              round
              color="default-pink"
              @click="scrollToRight"
              icon="chevron_right"
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabsWithBtn",
  props: {

  },
  data() {
    return {

    };
  },

  methods: {
    scrollToLeft() {
      document
          .querySelector(".tab")
          .scrollTo(document.querySelector(".tab").scrollLeft + -100, 0);
    },
    scrollToRight() {
      document
          .querySelector(".tab")
          .scrollTo(document.querySelector(".tab").scrollLeft + 100, 0);
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.tab {
  background-color: #ffffff;
  overflow-x: hidden;
  width: 50%;
}
.tab button {
  font-size: 15px;
  align-self: center;
  background-color: inherit;
  cursor: pointer;
  transition: 0.3s;
}
</style>